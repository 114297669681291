export default {
  props: {
    showAuto: {
      type: Boolean,
      default: false
    },
    goodDetail: {
      type: Object,
      default: function () {
        return {};
      }
    },
    activityDataState: {
      type: Number,
      default: 0
    },
    selectGoodIdList: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data() {
    return {
      buttonLoading: false,
      touchData: {
        clientX: null,
        clientY: null,
        moveNum: 0
      },
      selectIndex: -1,
      selectData: {},
      itemList: []
    };
  },
  computed: {},
  watch: {
    showAuto(data) {
      if (data) {
        this.$noScroll();
      } else {
        this.$canScroll();
      }
    }
  },
  async mounted() {},
  methods: {
    selectGood(type, item) {
      console.log("1111");
      this.$emit('selectGood', type, item);
    },
    close() {
      this.$emit('close', true);
    },
    touchstart(e) {
      this.touchData.clientX = e.changedTouches[0].clientX;
      this.touchData.clientY = e.changedTouches[0].clientY;
      this.touchData.moveNum = 0;
    },
    touchmove(e) {
      const subY = e.changedTouches[0].clientY - this.touchData.clientY;
      if (subY > 0) {
        this.touchData.moveNum = subY;
      }
    },
    touchend(e) {
      let that = this;
      const subY = e.changedTouches[0].clientY - this.touchData.clientY;
      if (subY > 100) {
        setTimeout(function () {
          that.touchData.moveNum = 0;
        }, 500);
        this.close();
      } else {
        that.touchData.moveNum = 0;
      }
    }
  }
};