import "vant/es/image/style";
import _Image from "vant/es/image";
import "core-js/modules/es.array.push.js";
import goodDetailComp from '../../components/good-detail-comp';
export default {
  name: 'detail',
  components: {
    [_Image.name]: _Image,
    goodDetailComp
  },
  data() {
    return {
      goodDetailAuto: false,
      activityData: {
        activity: {}
      },
      goodDetail: {},
      selectGoodList: []
    };
  },
  computed: {
    selectGoodIdList: function () {
      return this.selectGoodList.map(item => {
        return item.id;
      });
    }
  },
  async created() {
    let {
      id
    } = this.$route.params;
    let result = await this.$request({
      method: 'get',
      url: '/app/api/my/order/' + id
    });
    console.log(result);
    if (result.code == 0) {
      this.activityData = result.data;
      // await this.$store.commit('setGiftData',this.giftData)
    } else {
      this.$router.replace('/');
    }
  },
  async mounted() {},
  methods: {
    selectGoodsss(type) {
      console.log(type);
    },
    seeDetail(item) {
      this.goodDetail = item;
      this.goodDetailAuto = true;
    },
    selectGood(type, item) {
      console.log(item);
      if (item.store <= 0) {
        this.$toast({
          message: '该商品没有库存了，选选择其他商品'
        });
        return false;
      }
      if (type == 'add') {
        this.selectGoodList.push(item);
      } else {
        for (let i in this.selectGoodList) {
          if (this.selectGoodList[i].id == item.id) {
            this.selectGoodList.splice(i, 1);
            break;
          }
        }
      }
      this.$store.commit('setGiftData', this.selectGoodList);
    },
    goExchange() {
      if (this.selectGoodIdList.length != this.activityData.activity.typeNum) {
        this.$toast({
          message: '请选择 ' + this.activityData.activity.typeNum + ' 个礼品'
        });
        return false;
      }
      this.$router.push('/exchange/' + this.activityData.id);
    }
  }
};