var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "page-mask-div",
    class: {
      'page-mask-div-cur': _vm.showAuto
    },
    on: {
      "touchmove": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
      },
      "click": _vm.close
    }
  }), _c('div', {
    staticClass: "window-div",
    class: {
      'window-div-cur': _vm.showAuto
    },
    style: 'transform: translateY(' + _vm.touchData.moveNum + 'px)',
    on: {
      "touchstart": _vm.touchstart,
      "touchmove": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.touchmove.apply(null, arguments);
      },
      "touchend": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.touchend.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "top-line"
  }), _c('div', {
    staticClass: "window-content",
    on: {
      "touchmove": function ($event) {
        $event.stopPropagation();
      },
      "touchend": function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('div', {
    staticClass: "good-info"
  }, [_c('div', {
    staticClass: "cover-div"
  }, [_c('van-image', {
    attrs: {
      "width": "100%",
      "height": "100%",
      "fit": "cover",
      "src": _vm.goodDetail.cover
    }
  })], 1), _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.goodDetail.name))]), _vm.selectGoodIdList.includes(_vm.goodDetail.id) ? _c('div', {
    staticClass: "but",
    staticStyle: {
      "background": "rgba(216, 156, 61, 1)"
    },
    on: {
      "click": function ($event) {
        return _vm.selectGood('cancel', _vm.goodDetail);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-13.png"
    }
  }), _vm._v(" 已选择 ")]) : _vm.activityDataState == 0 ? [_vm.goodDetail.store <= 0 ? _c('div', {
    staticClass: "but",
    staticStyle: {
      "background": "#cccccc"
    },
    on: {
      "click": function ($event) {
        return _vm.selectGood('add', _vm.goodDetail);
      }
    }
  }, [_vm._v("暂无库存")]) : _c('div', {
    staticClass: "but",
    on: {
      "click": function ($event) {
        return _vm.selectGood('add', _vm.goodDetail);
      }
    }
  }, [_vm._v("选择")])] : _vm._e()], 2)]), _c('div', {
    staticClass: "line-div"
  }), _c('div', {
    staticClass: "detail-box"
  }, [_c('div', {
    staticClass: "s-title"
  }, [_vm._v("产品详情")]), _c('div', {
    staticClass: "detail",
    domProps: {
      "innerHTML": _vm._s(_vm.goodDetail.detail)
    }
  })])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };