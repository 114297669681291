var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('ticket-step-comp', {
    attrs: {
      "index": 1
    }
  }), _vm.activityData.activity.id == 75 ? _c('div', {
    staticClass: "tip-div-lin"
  }, [_vm._m(0)]) : _vm._e(), _c('div', {
    staticClass: "tip-div"
  }, [_vm._v("您可以选择"), _c('span', [_vm._v(_vm._s(_vm.activityData.activity.typeNum) + "份")]), _vm._v("奖品")]), _c('div', {
    staticClass: "good-list"
  }, _vm._l(_vm.activityData.activity.goods, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "item",
      on: {
        "click": function ($event) {
          return _vm.seeDetail(item);
        }
      }
    }, [_c('div', {
      staticClass: "cover"
    }, [_c('van-image', {
      attrs: {
        "width": "100%",
        "height": "100%",
        "fit": "cover",
        "src": item.cover
      }
    })], 1), _c('div', {
      staticClass: "info"
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.name))]), _c('div', {
      staticClass: "button-box"
    }, [_c('div', {
      staticClass: "store"
    }, [_vm._v("库存：" + _vm._s(item.store))]), _vm.selectGoodIdList.includes(item.id) ? _c('div', {
      staticClass: "but",
      staticStyle: {
        "background": "rgba(216, 156, 61, 1)"
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.selectGood('cancel', item);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-13.png"
      }
    }), _vm._v(" 已选择 ")]) : _vm.activityData.state == 0 ? [item.store <= 0 ? _c('div', {
      staticClass: "but",
      staticStyle: {
        "background": "#cccccc"
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.selectGood('add', item);
        }
      }
    }, [_vm._v("暂无库存")]) : _c('div', {
      staticClass: "but",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.selectGood('add', item);
        }
      }
    }, [_vm._v("选择")])] : _vm._e()], 2)])]);
  }), 0), _c('foot-bar-box-comp', [_c('div', {
    staticClass: "foot-div"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('div', {
    staticClass: "t"
  }, [_vm._v("您可以选择" + _vm._s(_vm.activityData.activity.typeNum) + "份奖品")]), _vm.activityData.state == 0 ? _c('div', {
    staticClass: "y"
  }, [_vm._v("已选择 "), _c('span', [_vm._v(_vm._s(this.selectGoodList.length))]), _vm._v(" 份奖品")]) : _c('div', {
    staticClass: "y"
  }, [_vm._v("该活动您已领取")])]), _c('div', {
    staticClass: "right"
  }, [_vm.activityData.state == 0 && !_vm.activityData.expired ? _c('div', {
    staticClass: "but",
    class: {
      'ban': this.selectGoodList.length != _vm.activityData.activity.typeNum
    },
    on: {
      "click": _vm.goExchange
    }
  }, [_vm._v("立即兑换")]) : _vm.activityData.state == 0 && _vm.activityData.expired == true ? _c('div', {
    staticClass: "but",
    staticStyle: {
      "background": "#999999"
    }
  }, [_vm._v("已过期")]) : _c('div', {
    staticClass: "but",
    staticStyle: {
      "background": "#999999"
    }
  }, [_vm._v("已领取")])])])]), _c('good-detail-comp', {
    attrs: {
      "showAuto": _vm.goodDetailAuto,
      "selectGoodIdList": _vm.selectGoodIdList,
      "activityDataState": _vm.activityData.state,
      "goodDetail": _vm.goodDetail
    },
    on: {
      "selectGood": _vm.selectGood,
      "close": function ($event) {
        _vm.goodDetailAuto = false;
      }
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "detail"
  }, [_c('div', [_vm._v("温馨提示：大米无货，请选择防疫礼包!")])]);
}];
export { render, staticRenderFns };